<template>
  <div class="page">
    <h1 class="page__title">Who We Are</h1>
    <div class="container">
      <div class="page__wrapper">
        <div class="page__block">
          <p class="page__text">Welcome to Sticker-zone.com. We, at {{ companyName }}*, are proud to present you with our web shop of clothes with printed stickers. Feel free to browse the collection, just choose the category (hoodies, t-shirts, pants & sweatshirts) you’re interested in.</p>
        </div>
        <div class="page__block">
          <p class="page__text">In order to make a purchase, however, you would need to register an account, by providing your First name and Last name, email, phone number, create a password and choose whether you agree or not to subscribe to our newsletter. </p>
        </div>
        <div class="page__block">
          <p class="page__text">You can add multiple items to the cart. You can indicate the address information in the account settings section or directly in your shopping cart before paying for the order. As soon as you’ve added everything you’ve wanted you can proceed to the checkout page and provide the payment details for the transaction to be processed. If transaction is complete, the details of your order, including the tracking number, will be sent to your account email.</p>
        </div>
        <div class="page__block">
          <p class="page__text">The average turnaround time for fulfilling order is 3-5 working days. It may depend based on several factors including the logistics provider ability and your specific location. UK shipping may take up to 4-8 working days, EU - 5-10; some international orders may take up to 10-15 working days.</p>
        </div>
        <div class="page__block">
          <p class="page__text">In case of any issue, please write us at <a class="page__link" :href="'mailto:' + companyEmail">{{ companyEmail }}</a>. Our dedicated support Team is always there to help you!</p>
        </div>
        <div class="page__block">
          <p class="page__text">*{{ companyName }} is a London based Private limited Company located at {{companyAddress}}. Registration number is 15388881.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useCompanyStore} from "@/stores/company.js";
import {computed} from "vue";

const companyStore = useCompanyStore()

const companyName = computed(() => {
  return companyStore.companyInfo ? companyStore.companyInfo.companyName : ''
})
const companyEmail = computed(() => {
  return companyStore.companyInfo ? companyStore.companyInfo.companyEmail : ''
})
const companyAddress = computed(() => {
  return companyStore.companyAddress ? companyStore.companyInfo.companyAddress : ''
})
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.page {
  background-color: $white;
  padding-bottom: 100px;

  &__title {
    padding-block: 15px;
    background-color: rgba(238, 238, 238, 1);

    text-align: center;
    font-size: 32px;
    color: rgba(35, 56, 81, 1);
  }

  &__wrapper {
    margin-block: 50px;
    display: flex;
    flex-direction: column;
    gap: 35px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__ul {
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    color: rgba(35,56,81,1);

    span {
      font-weight: 700;
    }
  }

  &__link {
    font-weight: 700;
  }
}
</style>