<template>
  <div class="modal">
    <div class="modal__bg" @click="closeModal"></div>
    <div class="modal__content">
      <div class="modal__close" @click="closeModal">
        <img src="@icons/crossIcon.svg" alt="close">
      </div>
      <div class="modal__content-inner">
        <p class="modal__content-title">Available Options</p>
        <div class="modal__content-options">
          <p class="modal__content-text">Size</p>
          <div class="modal__content-items">
            <button
              class="modal__content-item"
              :class="[{ active: selectedSize === size }]"
              @click="selectSize(size)"
              v-for="size in product.attributes.available_sizes"
              :key="size"
            >
              {{ size }}
            </button>
          </div>
        </div>
        <div class="modal__content-options">
          <p class="modal__content-text">Color</p>
          <div class="modal__content-items">
            <button
              class="modal__content-item"
              :class="[{ active: selectedColor === color }]"
              :style="{ 'background-color': color }"
              @click="selectColor(color)"
              v-for="color in product.attributes.available_colors"
              :key="color"
            ></button>
          </div>
        </div>
      </div>
      <div class="modal__content-bottom">
        <div class="stepper">
          <input
              type="text"
              class="stepper__input"
              v-model="stepper"
          >
          <div class="stepper__buttons">
            <div class="stepper__buttons-button" @click="stepper += 1">
              <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.2929 15.2893C18.6834 14.8988 18.6834 14.2656 18.2929 13.8751L13.4007 8.98766C12.6195 8.20726 11.3537 8.20757 10.5729 8.98835L5.68257 13.8787C5.29205 14.2692 5.29205 14.9024 5.68257 15.2929C6.0731 15.6835 6.70626 15.6835 7.09679 15.2929L11.2824 11.1073C11.673 10.7168 12.3061 10.7168 12.6966 11.1073L16.8787 15.2893C17.2692 15.6798 17.9024 15.6798 18.2929 15.2893Z"
                      fill="currentColor"/>
              </svg>
            </div>
            <div class="stepper__buttons-button" @click="stepper -= 1">
              <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.70711 9.71069C5.31658 10.1012 5.31658 10.7344 5.70711 11.1249L10.5993 16.0123C11.3805 16.7927 12.6463 16.7924 13.4271 16.0117L18.3174 11.1213C18.708 10.7308 18.708 10.0976 18.3174 9.70708C17.9269 9.31655 17.2937 9.31655 16.9032 9.70708L12.7176 13.8927C12.3271 14.2833 11.6939 14.2832 11.3034 13.8927L7.12132 9.71069C6.7308 9.32016 6.09763 9.32016 5.70711 9.71069Z"
                      fill="currentColor"/>
              </svg>
            </div>
          </div>
        </div>
        <div class="modal__content-button" :class="[{ disabled: !selectedColor || !selectedSize}]" @click="addProductsToCart(stepper)">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.78571 5H18.2251C19.5903 5 20.5542 6.33739 20.1225 7.63246L18.4558 12.6325C18.1836 13.4491 17.4193 14 16.5585 14H6.07142M4.78571 5L4.74531 4.71716C4.60455 3.73186 3.76071 3 2.76541 3H2M4.78571 5L6.07142 14M6.07142 14L6.25469 15.2828C6.39545 16.2681 7.23929 17 8.23459 17H17M17 17C15.8954 17 15 17.8954 15 19C15 20.1046 15.8954 21 17 21C18.1046 21 19 20.1046 19 19C19 17.8954 18.1046 17 17 17ZM11 19C11 20.1046 10.1046 21 9 21C7.89543 21 7 20.1046 7 19C7 17.8954 7.89543 17 9 17C10.1046 17 11 17.8954 11 19Z"
                stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          ADD TO CART
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useAppStore} from "@/stores/app.js";
import {Orders} from "@/core/api/order.js";
import {computed, ref} from "vue";
import {useCartStore} from "@/stores/cart.js";
import {useAuthStore} from "@/stores/auth.js";

const appStore = useAppStore()
const authStore = useAuthStore()
const cartStore = useCartStore()

const props = defineProps({
  product: Object
})

const cartId = computed(() => {
  return cartStore.currentOrder ? cartStore.currentOrder.uuid : null
})
const accessToken = computed(() => {
  return authStore.accessToken ? authStore.accessToken : null
})

const stepper = ref(1)

const selectedSize = ref('')
const selectSize = (size) => {
    selectedSize.value = size
}

const selectedColor = ref('')
const selectColor = (color) => {
    selectedColor.value = color
}

const closeModal = () => {
  appStore.setProductAttributesVisible(false)
  appStore.setActiveProductAttributes(null)
}

const addProductsToCart = async (stepperValue) => {
  if (selectedSize.value && selectedColor.value) {
    let successful = true;
    for (let i = 0; i < stepperValue; i++) {
      const res = await addProductToCartFunction();
      if (!res.data.addProductToPendingOrder.order) {
        successful = false;
      }
    }
    if (successful) {
      const popup = {
        text: 'You have successfully added the products to the cart!',
        tag: 'success',
        id: Math.random() * 2000
      }
      appStore.popupArray.unshift(popup)
      appStore.setPopupVisible(true)

      closeModal()
    } else {
      const popup = {
        text: 'Something went wrong!',
        tag: 'fail',
        id: Math.random() * 2000
      }
      appStore.popupArray.unshift(popup)
      appStore.setPopupVisible(true)
    }
  } else {
    const popup = {
      text: 'You need to choose the options!',
      tag: 'fail',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)
  }
}

const addProductToCartFunction = async () => {
  const res = await Orders.overwriteCart("addProductToPendingOrder", accessToken.value, cartId.value, props.product.uuid, selectedColor.value, selectedSize.value)

  if (res.data.addProductToPendingOrder.order) {
    cartStore.currentOrder = res.data.addProductToPendingOrder.order;
  }
  return res;
}
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.modal {
  cursor: auto;
  position: fixed;
  z-index: 3;
  inset: 0;
  display: grid;
  place-items: center;

  &__bg {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
  }

  &__content {
    position: absolute;
    z-index: 2;
    width: 350px;
    border-radius: 2px;
    background-color: $white;

    &-inner {
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    &-title {
    margin-bottom: 15px;
    color: $dark-blue;
    font-weight: 700;
    font-size: 22px;

    &::after {
      content: '';
      width: 50px;
      height: 3px;
      background-color: $orange;
      display: block;
      position: relative;
      margin-top: 7px;
    }
  }

    &-options {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    &-text {
      font-size: 13px;
      color: rgba(35, 56, 81, 1);

      &::after {
        content: "*";
        color: rgba(232, 40, 77, 1);
      }
    }

    &-items {
      display: flex;
      align-items: center;
      gap: 13px;
    }

    &-item {
      cursor: pointer;
      border: 2px solid rgba(221, 221, 221, 1);
      background-color: rgba(237, 242, 254, 1);
      height: 30px;
      padding-inline: 10px;
      transition: 0.2s;

      font-size: 13px;
      color: rgba(35, 56, 81, 1);

      &:hover {
        border-color: rgba(143, 210, 238, 1);
        background-color: rgba(187, 198, 221, 1);
        box-shadow: 0 5px 30px -5px rgba(0, 0, 0, 0.15);
      }
    }

    &-bottom {
      display: flex;
      align-items: center;
      gap: 15px;
      background-color: rgba(237, 242, 254, 1);
      padding: 10px;
    }

    &-button {
      cursor: pointer;
      width: 100%;
      height: 41px;
      background-color: $blue;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      border-radius: 2px;
      transition: 0.2s;

      color: $white;
      font-size: 12px;
      font-weight: 700;

      &:hover {
        background-color: $orange;
      }
    }
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 4;
    background-color: $orange;
    border-radius: 50%;
    display: grid;
    place-items: center;
    padding: 5px;
    transition: 0.2s;

    &:hover {
      background-color: $blue;
    }
  }
}

.active {
  box-shadow: inset 0 0 15px -5px rgba(0, 0, 0, 0.4);
  border-color: rgba(143, 210, 238, 1);
  background-color: rgba(187, 198, 221, 1);
}

.disabled {
  background-color: $dark-grey;
  cursor: not-allowed;

  &:hover {
    background-color: $dark-grey;
  }
}

.stepper {
  display: flex;
  align-items: center;
  height: 30px;
  border: 1px solid $blue;

  &__input {
    width: 32px;
    height: 100%;
    background-color: $white;
    border-radius: 2px;
    box-shadow: 0 2px 15px -2px;

    text-align: center;
    color: #555;
    font-size: 15px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    width: 26px;
    height: 100%;
    border-left: 1px solid $blue;

    &-button {
      height: 15px;
      cursor: pointer;
      display: grid;
      place-items: center;
      transition: 0.2s;

      color: $blue;

      &:hover {
        color: rgba(143,210,238,1);
      }

      &:first-child {
        border-bottom: 1px solid $blue;
      }
    }
  }
}
</style>