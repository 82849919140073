<template>
  <div class="login">
    <h2 class="login__title">RETURNING CUSTOMER</h2>
    <div class="login__block">
      <p class="login__name">E-Mail Address</p>
      <div class="login__block-inner">
        <input
            type="email"
            placeholder="E-mail Address"
            class="login__block-input"
            v-model="email"
            @input="onEmailInput"
        >
        <p class="login__block-error" v-if="emailError">{{ emailError }}</p>
      </div>
    </div>
    <div class="login__block">
      <p class="login__name">Password</p>
      <div class="login__block-inner">
        <input
            type="password"
            placeholder="Password"
            class="login__block-input"
            v-model="password"
            @input="onPasswordInput"
        >
        <p class="login__block-error" v-if="passwordError">{{ passwordError }}</p>
      </div>
    </div>
    <router-link to="/forgot" class="login__forgot">Forgotten Password</router-link>
    <div class="login__line"></div>
    <button
        class="login__button"
        :disabled="!isFormValid"
        @click="login"
    >
      LOGIN
      <loader v-if="isLoading" />
      <img v-else src="@icons/arrowIcon.svg" alt="login">
    </button>
  </div>
</template>

<script setup>
import {computed, ref} from "vue";
import {isEmail, required} from "@/core/rules/textFieldRules.js";
import {Auth} from "@/core/api/auth.js";
import {useAuthStore} from "@/stores/auth.js";
import {useCartStore} from "@/stores/cart.js";
import {Orders} from "@/core/api/order.js";
import {useAuthModalsStore} from "@/stores/authModals.js";
import Loader from "@/components/UI/loader.vue";
import {useAppStore} from "@/stores/app.js";

const authStore = useAuthStore()
const cartStore = useCartStore()
const authModalsStore = useAuthModalsStore()
const appStore = useAppStore()

const isLoading = ref(false)

const email = ref('')
const password = ref('')

const emailError = ref('')
const passwordError = ref('')

const isFormValid = computed(() => {
  return isEmail(email.value) === true && password.value.length !== 0
})

const onEmailInput = (e) => {
  if (isEmail(e.target.value) !== true) {
    emailError.value = isEmail(e.target.value)
  } else {
    emailError.value = ""
  }
}
const onPasswordInput = (e) => {
  if (required(e.target.value) !== true) {
    passwordError.value = required(e.target.value)
  } else {
    passwordError.value = ""
  }
}

const login = async () => {
  isLoading.value = true

  const authResult = await Auth.Login({
    query: `mutation {
      obtainJwtToken(email: "${email.value}", password: "${password.value}") {
        accessToken
        refreshToken
        user {
          uuid
          firstName
          lastName
          email
          phoneNumber
          isSubscribed
          balance {
            amount
          }
        }
      }
    }`
  })

  if (authResult.data.obtainJwtToken?.user) {
    const popup = {
      text: 'You have successfully logged in',
      tag: 'success',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)

    authStore.setUser({
      user: authResult.data.obtainJwtToken.user,
      accessToken: authResult.data.obtainJwtToken.accessToken
    })
    localStorage.setItem('stz-refresh', authResult.data.obtainJwtToken.refreshToken)

    isLoading.value = false

    authModalsStore.setSignIn(false)

    const orderResult = await Orders.getPendingOrder(
      authResult.data.obtainJwtToken.accessToken,
      { userEmail: authResult.data.obtainJwtToken.user.email }
    );
    cartStore.setCurrentOrders(orderResult.data.orders.edges[0].node)
  } else {
    const popup = {
      text: 'You have entered incorrect credits',
      tag: 'fail',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)
  }
}
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.login {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;

  &__title {
    color: rgba(35,56,81,1);
    font-weight: 700;
    font-size: 15px;

    &::after {
      content: '';
      width: 50px;
      height: 3px;
      background-color: $orange;
      display: block;
      position: relative;
      margin-top: 7px;
    }
  }

  &__block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 13px;
    color: rgba(35,56,81,1);

    &-input {
      width: 320px;
      height: 34px;
      border: 1px solid rgba(238, 238, 238, 1);
      padding-inline: 12px;

      color: #555;
      font-size: 14px;

      &:hover {
        border-color: rgba(221, 221, 221, 1);
      }
    }

    &-error {
      margin-top: 5px;
      color: $red;
      font-size: 12px;
    }
  }

  &__name {
    margin-top: 10px;
    display: flex;
    gap: 3px;

    font-size: 13px;
    color: rgba(35,56,81,1);
  }

  &__forgot {
    transition: 0.2s;
    color: #337ab7;
    font-size: 16px;

    &:hover {
      color: $orange;
    }
  }

  &__line {
    margin-top: 20px;
    height: 1px;
    width: 100%;
    background-color: rgba(221, 221, 221, 1);
  }

  &__button {
    cursor: pointer;
    align-self: flex-end;
    background-color: $blue;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: 0.2s;
    border-radius: 3px;
    padding: 12px 15px;

    color: $white;
    font-size: 13px;
    font-weight: 700;

    &:hover {
      background-color: $orange;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: $dark-grey;
    }

    &:disabled:hover {
      background-color: $dark-grey;
    }
  }
}

@media (max-width: 500px) {
  .login {
    &__block {
      flex-direction: column;
      gap: 5px;

      &-input {
        width: 280px;
      }
    }
  }
}
</style>