<template>
  <footer class="footer">
    <div class="footer__top">
      <div class="container">
        <div class="footer__top-wrapper">
          <div class="footer__top-column">
            <p class="footer__title">ABOUT US</p>
            <router-link to="/privacy" class="footer__link">
              <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z"
                      fill="currentColor"/>
              </svg>
              Privacy Policy
            </router-link>
            <router-link to="/terms" class="footer__link">
              <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z"
                      fill="currentColor"/>
              </svg>
              Terms & Use
            </router-link>
            <router-link to="/license" class="footer__link">
              <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z"
                      fill="currentColor"/>
              </svg>
              License Agreement
            </router-link>
            <router-link to="/delivery" class="footer__link">
              <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z"
                      fill="currentColor"/>
              </svg>
              Delivery Policy
            </router-link>
            <router-link to="/return" class="footer__link">
              <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z"
                      fill="currentColor"/>
              </svg>
              Return and Refund Policy
            </router-link>
            <router-link to="/who-we-are" class="footer__link">
              <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z"
                      fill="currentColor"/>
              </svg>
              Who We Are
            </router-link>
          </div>
          <div class="footer__top-column" v-if="user">
            <p class="footer__title">MY ACCOUNT</p>
            <router-link to="/profile" class="footer__link">
              <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z"
                      fill="currentColor"/>
              </svg>
              My Account
            </router-link>
            <router-link to="/orders" class="footer__link">
              <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z"
                      fill="currentColor"/>
              </svg>
              Order History
            </router-link>
<!--            <router-link to="/blog" class="footer__link">-->
<!--              <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z"-->
<!--                      fill="currentColor"/>-->
<!--              </svg>-->
<!--              Newsletter-->
<!--            </router-link>-->
          </div>
          <div class="footer__top-column">
            <p class="footer__title">Don't want to miss out?</p>
            <p class="footer__text">Stay in the loop by signing up for our newsletter</p>
            <div class="footer__search">
              <input
                  type="text"
                  placeholder="Your email"
                  class="footer__search-input"
                  v-model="contactEmail"
              >
              <button
                  class="footer__search-button"
                  :disabled="!isFormValid"
                  @click="subscribe"
              >
                <svg width="15" height="15" viewBox="0 -4 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">
                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                    <g id="Icon-Set" sketch:type="MSLayerGroup" transform="translate(-412.000000, -259.000000)" fill="#ffffff">
                      <path d="M442,279 C442,279.203 441.961,279.395 441.905,279.578 L433,270 L442,263 L442,279 L442,279 Z M415.556,280.946 L424.58,271.33 L428,273.915 L431.272,271.314 L440.444,280.946 C440.301,280.979 415.699,280.979 415.556,280.946 L415.556,280.946 Z M414,279 L414,263 L423,270 L414.095,279.578 C414.039,279.395 414,279.203 414,279 L414,279 Z M441,261 L428,271 L415,261 L441,261 L441,261 Z M440,259 L416,259 C413.791,259 412,260.791 412,263 L412,279 C412,281.209 413.791,283 416,283 L440,283 C442.209,283 444,281.209 444,279 L444,263 C444,260.791 442.209,259 440,259 L440,259 Z"
                          id="mail" sketch:type="MSShapeGroup">
                      </path>
                    </g>
                  </g>
                </svg>
                Send
              </button>
            </div>
            <label class="footer__label">
              <input
                  type="checkbox"
                  v-model="isRead"
              >
              I have read and agree to the Privacy Policy
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="footer__bottom">
      <div class="footer__images">
        <img src="@images/applePay.png" alt="apple pay">
        <img src="@images/googlePay.png" alt="google pay">
        <img src="@images/visa.png" alt="visa">
        <img src="@images/visaSecure.png" alt="visa secure">
        <img src="@images/masterCard.png" alt="mastercard">
        <img src="@images/mcSecure.png" alt="mc Secure">
      </div>
      <p class="footer__bottom-text">Copyright © 2025, {{ companyInfo?.companyName }}, All Rights Reserved</p>
    </div>
  </footer>
</template>

<script setup>
import {useCompanyStore} from "@/stores/company.js";
import {computed, ref} from "vue";
import {useAuthStore} from "@/stores/auth.js";
import {useAppStore} from "@/stores/app.js";

const companyStore = useCompanyStore()
const authStore = useAuthStore()
const appStore = useAppStore()

const companyInfo = computed(() => {
  return companyStore.companyInfo ? companyStore.companyInfo : null
})

const user = computed(() => authStore.user)

const contactEmail = ref('')
const isRead = ref(false)

const isFormValid = computed(() => {
  return (
    contactEmail.value &&
    isRead.value === true
  )
})

const subscribe = () => {
  const popup = {
    text: 'You have successfully subscribed to our newsletter!',
    tag: 'success',
    id: Math.random() * 2000
  }
  appStore.popupArray.unshift(popup)
  appStore.setPopupVisible(true)
}
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.footer {
  background-color: $grey;

  &__top {

    &-wrapper {
      display: flex;
      justify-content: space-between;
      padding-block: 20px;
    }

    &-column {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }

  &__link {
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: 0.2s;

    color: #337ab7;
    font-size: 16px;

    & svg {
      transition: 0.2s;
      width: 16px;
      height: 16px;
    }
    
    &:hover {
      color: $orange;
    }
  }

  &__title {
    margin-bottom: 5px;
    color: $dark-blue;
    font-weight: 700;
    font-size: 16px;

    &::after {
      content: '';
      width: 50px;
      height: 3px;
      background-color: $orange;
      display: block;
      position: relative;
      margin-top: 7px;
    }
  }

  &__text {
    margin-block: 5px;
    color: $dark-blue;
    font-size: 16px;
  }

  &__search {
    margin-bottom: 5px;
    background-color: $white;
    border-radius: 3px;
    padding: 5px 5px 5px 10px;
    display: flex;

    &-input {
      width: 100%;

      color: #555;
      font-weight: 500;
    }

    &-button {
      cursor: pointer;
      background-color: $blue;
      border-radius: 3px;
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 10px;
      transition: 0.2s;

      color: $white;
      font-size: 11px;
      font-weight: 700;

      &:hover {
        background-color: $orange;
      }

      &:disabled {
        cursor: not-allowed;
        background-color: $dark-grey;
      }

      &:disabled:hover {
        background-color: $dark-grey;
      }
    }
  }

  &__label {
    color: $dark-blue;
    font-size: 13px;
  }

  &__bottom {
    background-color: $dark-blue;
    padding-block: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    &-text {
      font-size: 12px;
      color: $white;
    }
  }

  &__images {
    display: flex;
    align-items: center;
    gap: 10px;

    & img {
      height: 29px;
    }
  }
}

@media (max-width: 800px) {
  .footer {
    &__top {
      &-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 25px;
      }
    }
  }
}

@media (max-width: 800px) {
  .footer {
    &__top {
      &-wrapper {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>